import React from 'react';
import ReactDom from 'react-dom';

const Toolbox = {

    overview: (container) => {
        if (container && container.length > 0) {
            import("./components/ToolOverview").then(TO => {
                ReactDom.render(<TO.default />, document.getElementById(container));
            });
        }
    },

    my: (container, data) => {
        if (container && container.length > 0) {
            import("./components/MyToolbox").then(TO => {
                ReactDom.render(<TO.default data={data}/>, document.getElementById(container));
            });
        }
    },

    toggleDrawer: () => {

    },

    Run: {
        updateResultCount: (slug, value, url) => {
            const els = document.querySelectorAll('.run-row__resultcount[data-run="' + slug + '"]');
            const content = url ? `<a class="run-row__resultcountvalue" href="${url}">${value}</a>` : `<span class="run-row__resultcountvalue">${value}</span>`;
            els.forEach((e) => {
                e.innerHTML = content + e.innerHTML;
                e.classList.remove('hidden');
            });
        }
    },
};

document.addEventListener('DOMContentLoaded', () => {



});

window.Toolbox = Toolbox;